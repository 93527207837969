@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Armenian:wght@400;700;900&display=swap');
@import "suneditor/dist/css/suneditor.min.css";

* {
  margin: 0;
  padding: 0;
  /* box-sizing: unset; */
}

body {
  font-family: 'Noto Sans Armenian';
  background-color: #F9F9F9;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: #636363;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.se-controller.se-controller-resizing {
  display: none !important;
}

.se-resize-dot {
  display: none !important;
}

.se-modal-resize {
  display: none !important;
}

.se-resizing-bar {
  display: none !important;
}

.sun-editor {
  border: none !important;
}
